@import 'src/styles/imports';

.component {
  position: fixed;
  z-index: $z-index-header;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--themes-colors-primary-background);
  font-family: Roboto, sans-serif;

  .layout-container {
    position: relative;
    display: flex;
    max-width: $max-width-app;
    height: $height-top-nav;
    padding: 0 1em;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    grid-gap: 3em;

    .navigation {
      display: flex;
      margin-left: auto;
      align-items: center;
      text-wrap: none;
      grid-gap: .5em;

      @include not-desktop {
        display: none;
      }

      button {
        border: 0;
        background-color: $color-transparent;

        &:focus,
        &:active {
          border: 0;
          outline: 0;
        }
      }

      .menu-item {
        font-size: 14px;
        position: relative;
        display: inline-flex;
        height: 1.5em;
        line-height: 1;
        padding: 2px 0;
        margin-left: 1.25em;
        color: var(--themes-colors-primary-font-color);
        font-weight: 600;
        text-decoration: none;
        align-items: center;
        justify-content: center;
        text-wrap: none;

        &:hover {
          padding-bottom: 1px;
          border-bottom: 1px solid currentcolor;
          text-decoration: none;
          cursor: pointer;
        }

        &.active {
          padding-bottom: 1px;
          border-bottom: 1px solid currentcolor;
        }
      }

      .about-menu {
        position: relative;

        a {
          &:hover {
            text-decoration: none;
          }
        }

        svg {
          margin-left: 5px;
        }

        .about-us-dropdown {
          a {
            font-size: 14px;

            &:hover {
              font-size: 14px;
              border: 0;
              background-color: $color-white;
              color: $color-blue-dark;
              text-decoration: underline;
              cursor: pointer;
              transform: none;
              transition: none;
            }
          }
        }
      }
    }

    .advisory-button {
      font-size: 13px;
      line-height: 13px;
      padding: 15px 20px;
      border: 1px solid $color-black;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .menu {
    position: relative;
    width: 40px;
    height: 40px;
    padding: 0 .6em;
    margin-left: auto;

    @include desktop {
      display: none;
    }

    .bar-container {
      display: flex;
      width: 22px;
      height: 16px;
      flex-direction: column;
      grid-gap: 4px;
    }

    .bar {
      width: 100%;
      height: 2.5px;
      background-color: var(--themes-colors-primary-dark);
    }

    &:hover {
      cursor: pointer;
    }
  }
}
