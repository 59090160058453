@import 'src/styles/imports';

.component {
  max-width: 45em;
  margin: 0 auto;

  &.exp-theme {
    margin: 0;
  }

  &.fake-search .search-wrapper input {
    background-color: $color-white;
  }

  .search-options {
    margin: 0 auto 1.5em;

    @include mobile {
      margin: 32px 0;
    }
  }

  .search-wrapper {
    position: relative;
    display: flex;
    height: 65px;
    align-items: center;

    input {
      font-size: 1em;
      width: 100%;
      height: 100%;
      padding: 1em 14em 1em 1em;
      border: 0;
      border-radius: $border-radius-large;
      background-color: var(--themes-colors-tertiary-background);
      font-family: $font-family-sans-serif;
      outline: none;

      &::placeholder {
        font-size: .8125em;
      }
    }

    &.exp-wrapper input {
      border-radius: 0; // Use 0 instead of none
    }

    &.exp-wrapper button {
      color: $color-white;
    }

    button {
      font-size: 1em;
      top: 0;
      right: 0;
      display: flex;
      width: 6em;
      height: calc(100% - 1em);
      line-height: 1;
      padding: 0;
      border-radius: $border-radius-large;
      margin: .5em .5em .5em 0;
      align-items: center;
      justify-content: center;
      transition: all .3s ease;

      svg {
        height: 1em;
        margin-right: .2em;
        transform: rotate(90deg);

        path {
          fill: $color-black;
        }
      }

      &:hover {
        svg path {
          fill: $color-white;
        }
      }
    }

    .suggested-location-dropdown {
      margin-top: 1em;
    }
  }

  &.home-appraisal {
    .search-wrapper input,
    .search-wrapper button {
      border-radius: var(--themes-buttons-border-radius);
    }
  }

  @media (min-width: 880px) {
    .search-wrapper {
      justify-content: center;
    }
  }

  @include mobile {
    .search-wrapper {
      font-size: 10pt;
      height: 50px;

      button {
        font-size: 9pt;
        right: 0;
        width: 4.8em;
        padding: 0 .5em;
      }

      .filter-button {
        right: 5em;

        i {
          display: none;
        }
      }

      input {
        width: 100%;
        padding-right: 9em;
      }
    }
  }
}

.search-button-container {
  position: absolute;
  right: 0;
  display: flex;
  height: 100%;
  justify-content: flex-end;
}
