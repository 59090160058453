@import 'src/styles/imports';
@import 'src/styles/animations';

.component {
  display: flex;
  height: 100%;
  padding: 2em 1em;
  text-align: center;
  align-items: center;
  justify-content: center;

  svg {
    width: 5em;
    height: 5em;
    animation: spin 1.2s infinite linear;
    animation-direction: reverse;

    path {
      fill: $color-grey-4;
    }
  }
}
