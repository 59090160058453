@import 'src/styles/imports';

.component {
  position: absolute;
  z-index: $z-index-dropdown;
  top: 130%;
  right: 0;
  box-shadow: $box-shadow;
  background: $color-white;

  .menu-items {
    min-width: 11.5em;

    li {
      a {
        font-size: .9375em;
        width: 100%;
        height: auto;
        padding: .7em 1em;
        margin: 0;
        color: $color-grey-10;
        font-weight: 300;
        white-space: nowrap;
        justify-content: initial;

        &:hover {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: .3em;
            background-color: var(--themes-colors-primary);
          }
        }
      }
    }
  }

  @include desktop {
    .mobile {
      display: none;
    }
  }

  &.exp {
    top: 170%;
    padding: 1em 0;
    border-radius: 8px;

    .menu-items {
      li {
        a {
          font-size: 1em;
          display: block;
          font-weight: $font-weight-regular;
          text-align: left;
          transition: all .3s ease;

          &:hover {
            background-color: $color-dark-navy-bg;
            color: $color-modern-silver-gradient;
            font-weight: $font-weight-bold;

            &::after {
              content: none;
            }
          }
        }
      }
    }
  }
}
