@import 'src/styles/imports';



.component {
  position: fixed;
  z-index: $z-index-header;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--themes-colors-primary-background);

  .layout-container {
    @include full-width-layout-container;
    position: relative;
    display: flex;
    height: $height-top-nav;
    align-items: center;
    justify-content: space-between;

    &.expanded {
      margin-bottom: 3em;

      @include tablet {
        margin-bottom: 5em;
      }
      @include desktop {
        margin-bottom: 4em;
      }
    }
  }

  .logo {
    display: inline-block;
    line-height: 0;

    svg {
      display: inline-block;
      width: auto;
      max-width: 100%;
      height: 1.25em;
      vertical-align: middle;

      path {
        fill: var(--themes-colors-primary-font-color);
      }
    }

    &:hover {
      border-color: transparent;
    }
  }

  .navigation {
    display: flex;
    align-items: center;

    button {
      border: 0;
      background-color: $color-transparent;

      &:focus,
      &:active {
        border: 0;
        outline: 0;
      }
    }

    .zoocasa-search-button {
      padding: 20px 10px;
      border: 1px solid var(--themes-colors-primary-font-color);
      border-radius: 8px;

      .search-icon {
        font-size: .8em;
      }

      &:active,
      &:hover {
        color: $color-black;
        text-decoration: none;

        .search-icon {
          path {
            fill: $color-black;
          }
        }
      }

      &:hover {
        background-color: var(--themes-colors-primary-font-color);
      }

      &:active {
        background-color: $color-grey-3;
      }

    }

    .nav-btn,
    a {
      font-size: 1em;
      position: relative;
      display: inline-flex;
      height: 1.5em;
      line-height: 1;
      padding: 2px 0;
      margin-left: 1.25em;
      color: var(--themes-colors-primary-font-color);
      text-decoration: none;
      align-items: center;
      justify-content: center;

      &:hover {
        text-decoration: underline;
      }

      &.menu {
        position: relative;
        width: 3.6em;
        height: 3.6em;
        border: 1px solid var(--themes-colors-primary-font-color);
        border-radius: 50%;
        margin-left: 1.6em;
        background-color: $color-warm-black;
        font-weight: 300;

        &:hover {
          background-color: $color-white;
          color: $color-warm-black;
          font-weight: 700;
          text-decoration: none;
        }

        &:focus {
          background-color: $color-white;
          color: $color-warm-black;
          font-weight: 700;
        }
      }
    }

    svg {
      width: 1.125em;
      height: 1.125em;
      margin-right: .4em;

      path {
        fill: var(--themes-colors-primary-font-color);
      }
    }

    .country-flag {
      display: flex;
      flex-direction: row;

      img {
        display: flex;
        width: 1.5em;
        height: 1.125em;
        margin-right: .4em;
        align-self: center;
      }

      span {
        margin-top: .1em; // offsets the not perfectly cut svg
      }
    }

    button span {
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        text-decoration: underline;
      }
    }

    .dropdown-arrow-icon {
      width: .8em;
      height: .8em;
      margin-right: 0;
      margin-left: .3em;

      path {
        fill: var(--themes-colors-primary-font-color);
      }
    }
  }

  .header-icon {
    font-size: 1.125em;
    color: var(--themes-colors-primary-font-color);

    path {
      fill: var(--themes-colors-primary-font-color);
    }
  }

  .unclickable {
    pointer-events: none;
  }

  .mobile-right {
    display: flex;
    margin-right: -.6em;
    align-items: center;

    .zoocasa-search-button-mobile {
      font-size: 1em;
      padding: 12px 10px;
      border: 1px solid var(--themes-colors-primary-font-color);
      border-radius: 8px;
      margin-left: 1.25em;
      color: var(--themes-colors-primary-font-color);
      text-decoration: none;
      align-items: center;
      justify-content: center;

      .search-icon-mobile {
        font-size: .8em;
        margin-right: 5px
      }

      &:active,
      &:hover {
        color: $color-black;
        text-decoration: none;

        .search-icon-mobile {
          path {
            fill: $color-black;
          }
        }
      }

      &:hover {
        background-color: var(--themes-colors-primary-font-color);
      }

      &:active {
        background-color: $color-grey-3;
      }
    }

    svg {
      width: 1.125em;
      height: 1.125em;

      path {
        fill: var(--themes-colors-primary-font-color);
      }
    }
  }

  @include mobile {
    .layout-container {
      height: $height-top-nav-mobile;
    }

    .logo-wrapper {
      display: flex;
      align-items: center;
    }

    .logo {
      svg {
        height: 1em;
      }
    }
  }

  @media (min-width: 1050px) {
    .mobile-right {
      display: none;
    }
  }

  @media (max-width: 1050px) {
    .navigation {
      display: none;
    }
  }
}

.search .header-icon {
  font-size: 1.125em;
  color: var(--themes-colors-primary-font-color);
}

.panel {
  position: absolute;
  top: 4em;
  right: 0;
  left: 0;
  margin: 0 auto;
  background-color: var(--themes-colors-primary-font-color);

  @include desktop {
    top: 4em;
    right: 25%;
    width: 31.25em;
    border: 1px solid $color-grey-4;
    border-radius: $border-radius-medium;
  }

  @media (min-width: $breakpoint-tablet) and (max-width: 900px) {
    right: 20px;
    left: auto;
  }
}

.full-screen-search {
  position: absolute;
  z-index: $z-index-full-screen-background;
  width: 100%;
  height: 100vh;
  border-top: 1px solid $color-grey-3;
  background: $color-white;

  @include not-mobile {
    display: none;
  }
}

