@import 'src/styles/imports';

.component {
  position: fixed;
  z-index: $z-index-header;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  box-shadow: $box-shadow-spread;
  height: $height-bottom-nav-mobile;
  padding: 0 1em;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: $color-white;
  color: $color-grey-9;
  align-items: center;
  justify-content: space-around;
  transition: all .3s ease-in-out;

  .map-chip {
    position: fixed;
    z-index: 9000;
    bottom: 90px;
    left: calc(50% - 41px);
    display: flex;
    width: 83px;
    height: 38px;
    padding: 10px 16px;
    border-radius: 8px;
    background-color: $color-black;
    color: $color-white;
    align-items: center;
    justify-content: space-between;

    &.exp-theme {
      box-shadow: $box-shadow-mobile-map-button;
      padding: 8px 16px;
      border-radius: var(--themes-buttons-border-radius);
      background-color: $color-white;
      color: var(--themes-primary-font-color);
      justify-content: center;

      svg path  {
        fill: var(--themes-primary-font-color);
      }

      &:hover {
        background-color: var(--themes-colors-primary);
        color: $color-white;

        svg path  {
          fill: $color-white;
        }
      }

      &.active {
        background-color: $color-dark-navy-80;
        color: $color-white;

        svg path  {
          fill: $color-white;
        }
      }
    }

    svg path  {
      fill: $color-white;
    }
  }

  &.is-visible {
    transform: translateY(0);
  }

  &.is-hidden {
    transform: translateY(80px);
  }

  .item {
    font-size: .6875em;
    display: flex;
    color: $color-grey-9;
    cursor: pointer;
    align-items: center;
    flex-direction: column;

    &:hover {
      text-decoration: underline;
    }
  }
}

.icon {
  width: 1.75em;
  height: 1.75em;
  margin-bottom: .5em;
}

.active {
  font-weight: $font-weight-bold;

  g {
    g {
      fill: $color-sand;
    }
  }

  .icon-heart {
    g {
      g {
        fill: $color-white;
        stroke: $color-sand;
      }
    }
  }
}
