@import 'src/styles/imports';

.page {
  background-color: $color-white;
}

.loading {
  display: flex;
  min-height: 100vh;
  background-color: $color-white;
  align-items: center;
  justify-content: center;

  @include mobile {
    margin-top: $height-top-nav-mobile;
  }
}

.mobile-nav {
  position: fixed;
  z-index: $z-index-bump;
  right: 0;
  bottom: 0;
  left: 0;
}

@include desktop {
  .mobile-nav {
    display: none;
  }
}
