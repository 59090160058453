@import 'src/styles/imports';

.component {
  .button-group {
    box-shadow: none;
    border-radius: $border-radius-large + 2 !important; // sass-lint:disable-line no-important
    background-color: $color-grey-8;

    .button-style {
      font-size: 17px;
      height: 2.5em;
      color: $color-white;

      @include mobile {
        font-size: 14px;
      }

      &:hover {
        background-color: $color-grey-light;
      }
    }

    .button-style-selected {
      border-radius: $border-radius-large !important; // sass-lint:disable-line no-important
      background-color: $color-white;
      color: $color-grey-6;
      font-weight: 700;

      &:hover {
        background-color: $color-white;
      }
    }

    &.exp-theme {
      padding: 4px;
      background-color: var(--themes-colors-transparent-background);

      .button-style {
        font-size: 16px;
        padding: 24px;
        font-weight: 400;

        &:hover {
          background-color: var(--themes-colors-transparent-background);
        }
      }

      .button-style-selected {
        background-color: var(--themes-colors-tertiary-background);
        color: var(--themes-colors-primary);

        &:hover {
          background-color: var(--themes-colors-tertiary-background);
        }
      }
    }
  }
}
